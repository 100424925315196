import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

import SectionContainer from '../common/SectionContainer';
import Header3 from '../common/text/Header3';
import Paragraph from '../common/text/Paragraph';
import ButtonLink from '../common/ButtonLink';
import Icon from '../icons/Icon';

import { mediaQueryMaxWidth } from '../theme/breakpoints';

import feeScheduleCopy from '../copy/fee-schedule.json';

export default function FeeSchedule () {
    const isSmallScreen = useMediaQuery(mediaQueryMaxWidth('sm'));

    return (
        <SectionContainer id='hireme' styles={{ marginBottom: '6.5vh' }}>
            <Header3>{feeScheduleCopy.title}</Header3>

            {
                feeScheduleCopy.paragraphs.map(text => (
                    <Paragraph>{text}</Paragraph>
                ))
            }

            <Grid 
                container
                rowSpacing={2}
                direction={isSmallScreen ? 'column' : 'row'} 
                sx={{ margin: '10px 0' }}>
                {
                    feeScheduleCopy.fees.map(schedule => (
                        <Grid container item direction='column' sm={4}>
                            <Paragraph styles={{ padding: '20px 0', backgroundColor: 'primary.main' }} color='primary.contrastText' position='centered'>{schedule.label}</Paragraph>

                            <Paragraph styles={{ padding: '20px 0' }} position='centered'>{schedule.fee}</Paragraph>

                            <Paragraph styles={{ padding: '20px 0', backgroundColor: 'neutral.contrast' }} position='centered'>{schedule.discount}</Paragraph>
                        </Grid>
                    ))
                }
            </Grid>

            <Grid 
                container 
                justifyContent='center'
                sx={{ margin: '20px 0 10px' }}>
                <ButtonLink 
                    styles={{ padding: '10px 20px', color: 'primary.contrastText' }} 
                    href={'mailto:jamiesonbates@gmail.com'}
                    fillColor='action'
                    startIcon={<Icon name='email' color='buttonText' size='large' />}>
                    Email Me
                </ButtonLink>
            </Grid>
        </SectionContainer>
    )
}
